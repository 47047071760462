import HeaderError from "../header/HeaderError";

type Props = {
  error: any;
};

const ErrorTemplate = ({ error }: Props) => {
  return (
    <>
      <HeaderError style="primary" code="500" referer="" error={error} />
    </>
  );
};

export default ErrorTemplate;
